import React, { useContext, useState, useMemo, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { NavigationContext } from '../navigation';
import './index.scss';

export default function HomepageMessages ({ messages }) {
  const navigationMap = useContext(NavigationContext);
  const [ showIndex, setShowIndex ] = useState(0);
  const [ pauseAutoSlide, setPauseAutoSlide ] = useState(false);

  useEffect(() => {
    if (pauseAutoSlide) return;

    const slideTimeout = setTimeout(() => {
      if (pauseAutoSlide) return;
      setShowIndex((showIndex + 1) % messages.length);
    }, 5000);

    return () => clearTimeout(slideTimeout);
  }, [ messages, showIndex, pauseAutoSlide ]);

  const messagesRendered = useMemo(() => {
    return messages.map((message, i) => {
      let className = 'homepage-messages-message';

      if (showIndex === i) className += ' show';

      let cta = null;
      let ctaHref = navigationMap.get(message.ctaTarget?.id)?.path;
      if (ctaHref) {
        cta = (
          <Link className="cta" to={ctaHref}>
            {message.ctaText}
          </Link>
        );
      }

      return (
        <li
          className={className}
          key={i}
          onMouseOver={() => setPauseAutoSlide(true)}
          onMouseOut={() => setPauseAutoSlide(false)}
        >
          <h5>{message.title?.title}</h5>
          <p>{message.text?.text}</p>
          {cta}
        </li>
      );
    });
  }, [ messages, navigationMap, showIndex ]);

  const handleMessageClick = (index, ev) => {
    ev.preventDefault();
    setShowIndex(index);
  };

  const selectorTabs = useMemo(() => {
    return messages.map((message, i) => {
      let className = 'homepage-messages-selector-tab';

      if (showIndex === i) className += ' active';

      return (
        <button
          key={i}
          className={className}
          onClick={handleMessageClick.bind(this, i)}
          aria-label={`Show message ${i+1}`}
        >
          <div className="__line" role="presentation"></div>
        </button>
      );
    });
  }, [ showIndex, messages ]);

  return (
    <aside className="homepage-messages">
      <ul>{messagesRendered}</ul>
      <div className="homepage-messages-selector">
        {selectorTabs}
      </div>
    </aside>
  );
}

export const query = graphql`
fragment homepageMessageFields on ContentfulHomepageMessage {
  title { title }
  text { text }
  ctaText

  ctaTarget {
    __typename
    ... on ContentfulAboutPage { id }
    ... on ContentfulCareersPage { id }
    ... on ContentfulContactUsPage { id }
    ... on ContentfulEvent { id }
    ... on ContentfulGenericPage { id }
    ... on ContentfulNewsletterSignupPage { id }
    ... on ContentfulResource { id }
    ... on ContentfulResourcesPage { id }
    ... on ContentfulSolution { id }
    ... on ContentfulSolutionCategory { id }
  }
}
`;
