import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Layout, renderModules } from '../components';
import { HeroBackgroundImage } from '../components/images';
import RichText from '../components/rich-text';
import HomepageMessages from '../components/homepage-messages';
import CtaButton from '../components/cta-button';
import heroLogoImg from '../images/hero-logo.svg';
import './index.scss';

export default function Homepage () {
  const data = useStaticQuery(graphql`
  query MyQuery {
    contentfulHomepage {
      title, heroHeading, heroCtaText
      metaDescription { metaDescription }
      heroText { raw }

      ogImage: heroBackgroundImage {
        ...pageOgImageFields
      }

      heroBackgroundImage {
        ...heroBackgroundImageFields
      }

      heroCtaTarget {
        __typename
        ... on ContentfulAboutPage { id }
        ... on ContentfulCareersPage { id }
        ... on ContentfulContactUsPage { id }
        ... on ContentfulEvent { id }
        ... on ContentfulGenericPage { id }
        ... on ContentfulNewsletterSignupPage { id }
        ... on ContentfulResource { id }
        ... on ContentfulResourcesPage { id }
        ... on ContentfulSolution { id }
        ... on ContentfulSolutionCategory { id }
      }

      messages {
        __typename
        ... on ContentfulHomepageMessage {
          ...homepageMessageFields
        }
      }

      modules {
        __typename
        ... on ContentfulBeyondZeroHarmModule {
          ...beyondZeroHarmModuleFields
        }
        ... on ContentfulContactModule {
          ...contactModuleFields
        }
        ... on ContentfulCtaArrowsModule {
          ...ctaArrowsModuleFields
        }
        ... on ContentfulFeatureModule {
          ...featureModuleFields
        }
        ... on ContentfulFeaturedResourcesModule {
          ...featuredResourcesModuleFields
        }
        ... on ContentfulFeaturedSolutionsModule {
          ...featuredSolutionsModuleFields
        }
        ... on ContentfulImageVideoModule {
          ...imageVideoModuleFields
        }
        ... on ContentfulImagesWithArrowModule {
          ...imagesWithArrowModuleFields
        }
        ... on ContentfulLeadershipModule {
          ...leadershipModuleFields
        }
        ... on ContentfulPrimaryContentModule {
          ...primaryContentModuleFields
        }
        ... on ContentfulQuoteModule {
          ...quoteModuleFields
        }
        ... on ContentfulSideBlockModule {
          ...sideBlockModuleFields
        }
        ... on ContentfulTwoColumnModule {
          ...twoColumnModuleFields
        }
      }
    }
  }
  `);

  const {
    title, heroHeading, metaDescription, heroCtaText, heroText, modules, messages,
    heroBackgroundImage, heroCtaTarget, ogImage
  } = data.contentfulHomepage;

  return (
    <Layout currentPath="/">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription?.metaDescription} />
        <meta name="og:title" content={title} />
        <meta name="og:description" content={metaDescription?.metaDescription} />
        <meta name="og:image" content={ogImage?.fixed?.src} />
      </Helmet>

      <div className={`home-hero`}>
        <HeroBackgroundImage heroBackgroundImage={heroBackgroundImage} />
        <div className="container">
          <div className={`row reversd_colum`}>
            <div className={`banner-text col-md-7 col-sm-12`}>
              <h1>{heroHeading}</h1>
              <div className="hero-text"><RichText node={heroText} /></div>
              <CtaButton ctaText={heroCtaText} ctaTarget={heroCtaTarget} />
            </div>
            <img className="hero-logo" src={heroLogoImg} alt="" role="presentation" />
          </div>
        </div>
        <HomepageMessages messages={messages} />
      </div>

      {renderModules(modules)}
    </Layout>
  );
}
